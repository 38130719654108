import { FC } from 'react';
import { generatePath } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { CompanyDetail } from 'app/api/company';
import { ROUTES } from 'app/routes/constants';
import { PageLoader } from 'components';
import { Link } from 'components/Link';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { QuoteRequestDetailsForm } from '../QuoteRequestDetailsForm';
import { MultiplePopups } from '../MultiplePopups';
import { useQuoteRequestDetails } from './hooks';
import { styles } from './styles';

export interface QuoteRequestDetailsProps {
  company: Pick<CompanyDetail, 'address' | 'id' | 'name' | 'displayId'>;
  subTitle: string;
  isOpen: boolean;
  quoteId: number;
  isSampleRequest: boolean;
  handleClose: () => void;
}

export const QuoteRequestDetails: FC<QuoteRequestDetailsProps> = ({
  company,
  subTitle,
  quoteId,
  isSampleRequest,
  isOpen = false,
  handleClose,
}) => {
  const { isLoading, quoteData, closeDetails } = useQuoteRequestDetails(quoteId, handleClose);

  const companyRoute = generatePath(ROUTES.common.company, { displayId: company.displayId });
  const modalTitle = (
    <Link path={companyRoute} sx={styles.companyLink}>
      <Typography sx={styles.title}>
        {company.name}
        {isSampleRequest && (
          <Typography component="span" sx={styles.sampleLabel}>
            {' '}
            - Sample Request
          </Typography>
        )}
      </Typography>
    </Link>
  );
  return (
    <MultiplePopups
      title={modalTitle}
      isOpen={isOpen}
      isChatOpen={false}
      handleClose={closeDetails}
      paperStyles={styles.paperStyles(true) as SxPropsTypes}
      // eslint-disable-next-line react/no-unstable-nested-components
      chat={() => <></>}
    >
      {isLoading ? (
        <Box sx={{ ...styles.loaderBox }}>
          <PageLoader />
        </Box>
      ) : (
        <QuoteRequestDetailsForm
          quoteData={quoteData}
          quoteId={quoteId}
          subTitle={subTitle}
          closeDetails={closeDetails}
        />
      )}
    </MultiplePopups>
  );
};
