import IntlTelInput from 'intl-tel-input/reactWithUtils';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { FC, useEffect, useState } from 'react';
import 'intl-tel-input/styles';
import { Control, useController } from 'react-hook-form';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorSmall.svg';

import { Box, FormControl, Typography } from '@mui/material';
import { styles } from './styles';

export interface PhoneFormFieldProps {
  setValue: (val: string) => void;
  setError?: (val: string | undefined) => void;
  initialValue: string;
  wrapStyles?: SxPropsTypes;
  placeholder?: string;
  autoComplete?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, object>;
}

export const PhoneFormField: FC<PhoneFormFieldProps> = ({
  control,
  wrapStyles,
  initialValue,
  setValue,
  setError,
  placeholder,
  autoComplete,
}) => {
  useController({
    name: 'phone',
    control,
  });

  const [initialCountryCode, setInitialCountryCode] = useState('');
  useEffect(() => {
    fetch('https://ipapi.co/json')
      .then((res) => {
        return res.json();
      })
      .then((data: { country_code: string }) => {
        setInitialCountryCode(data?.country_code);
      })
      .catch(() => {
        setInitialCountryCode('auto');
      });
  }, []);
  const [number, setNumber] = useState(initialValue);
  const [error, setFormError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    if (isValid) {
      setFormError(false);
      if (setError) setError(undefined);
      setValue(number);
    } else {
      setValue('');
    }
  }, [setValue, number, isValid, setError]);

  if (!initialCountryCode) {
    return null;
  }
  const onChange = (val: string) => {
    if (val === '' && setError && error) {
      setFormError(false);
      setError(undefined);
    }
    setNumber(val);
  };
  const onBlur = () => {
    if (number && !isValid) {
      setFormError(true);
      if (setError) setError('Phone Number is not valid.');
    }
  };
  return (
    <Box sx={{ ...styles.wrap, ...wrapStyles }}>
      <FormControl variant="outlined" sx={{ ...styles.control }}>
        <Box sx={{}}>
          <IntlTelInput
            onChangeNumber={(val: string) => onChange(val)}
            onChangeValidity={setIsValid}
            initOptions={{
              initialCountry: initialCountryCode,
            }}
            initialValue={number}
            inputProps={{
              placeholder,
              autoComplete,
              onBlur,
            }}
          />
        </Box>
      </FormControl>
      {error && (
        <Box sx={styles.errorBox}>
          <Box sx={styles.errorIcon}>
            <ErrorIcon />
          </Box>
          <Typography sx={styles.errorText}>Phone Number is not valid.</Typography>
        </Box>
      )}
    </Box>
  );
};
