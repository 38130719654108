import { ROUTES } from 'app/routes/constants';

interface HeaderNavigationItem {
  text: string;
  path: string;
  hidden?: boolean;
}

export const headerNavigationItems = (isBuyer: boolean, isActiveUser: boolean): HeaderNavigationItem[] => [
  {
    text: 'Home',
    path: ROUTES.buyer._,
    hidden: !isBuyer && isActiveUser,
  },
  {
    text: 'Dashboard',
    path: isBuyer ? ROUTES.buyer.dashboard._ : ROUTES.seller.dashboard._,
    hidden: !isActiveUser,
  },
  {
    text: 'About',
    path: ROUTES.common.about,
    hidden: isActiveUser,
  },
  {
    text: 'Contact us',
    path: ROUTES.common.contact,
  },
];
