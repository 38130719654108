import { FC, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import { postCompanyImage } from 'app/api/company';
import { notificationObserver } from 'utils/observer';

import { EditButton } from 'components';
import imagePlaceholder from 'assets/images/imagePlaceholderLarge.png';
import { useImageHandlers, useModal } from 'hooks';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { UnitsType } from 'app/api/quotes';
import { getUnitTypeLabel } from 'components/UnitsMenu';

import { getFileExtension } from '../../utils';
import { CompanyActionsButtons } from '../CompanyActionsButtons';
import { styles } from './styles';
import { EditCompanyDescription } from '../EditCompanyDescription';

interface DescriptionProps {
  displayId: string;
  isProductsList: boolean;
  hideAllButtons?: boolean;
  image?: string | null;
  description?: string;
  isSelf: boolean;
  minOrder?: number | null;
  minOrderType?: UnitsType;
  onRequestQuoteClick?: () => void;
}

export const Description: FC<DescriptionProps> = ({
  displayId,
  isProductsList,
  image,
  description,
  isSelf,
  minOrder = 0,
  minOrderType = UnitsType.KG,
  hideAllButtons = false,
  onRequestQuoteClick,
}) => {
  const { handleImageError } = useImageHandlers();
  const avatarExtension = getFileExtension(image);
  const isPdf = avatarExtension === 'pdf';
  const { open, handleCloseModal, handleOpenModal } = useModal();
  const onClickEdit = () => {
    handleOpenModal();
  };

  const [uploadedImg, setUploadedImg] = useState<{ file: File; url: string } | undefined>();

  const updateCompanyImage = useMutation({
    mutationFn: async (data: { displayId: string; image: File }) => {
      return postCompanyImage(data.displayId, data.image);
    },
  });

  const { getRootProps: getDropzoneRootProps } = useDropzone({
    onDrop: (files: File[]) => {
      // upload image to server
      setUploadedImg({
        file: files[0],
        url: URL.createObjectURL(files[0]),
      });
      try {
        updateCompanyImage.mutate({ displayId, image: files[0] });
        notificationObserver.publish({
          type: 'success',
          title: 'Updated company image',
        });
      } catch (err) {
        notificationObserver.publish({
          type: 'warning',
          title: 'Unable to updated company image',
        });
      }
    },
    maxSize: 15000000,
    multiple: false,
    noClick: false,
  });

  return (
    <Box sx={{ ...styles.wrap }}>
      {!!image && !isPdf && (
        <Box sx={styles.imageContainer}>
          <Box
            component="img"
            loading="lazy"
            src={uploadedImg?.url || image}
            sx={{ ...styles.image }}
            onError={handleImageError(imagePlaceholder)}
          />
          {isSelf && (
            <Box {...getDropzoneRootProps()} sx={styles.dropzone}>
              Upload new cover photo
              <UploadIcon />
            </Box>
          )}
        </Box>
      )}
      <Box sx={{ ...styles.contentWrap }}>
        <Box sx={{ ...styles.descriptionWrap }}>
          {description && (
            <>
              <Typography component="h3" sx={styles.title}>
                About
                {isSelf && (
                  <Box sx={styles.editContainer}>
                    <EditButton onClick={onClickEdit} />
                  </Box>
                )}
              </Typography>
              <Typography sx={{ ...styles.description }}>{description}</Typography>
            </>
          )}
          {!!minOrder && (
            <Typography sx={{ ...styles.minOrder }}>
              Minimum Order: {minOrder} {getUnitTypeLabel(minOrderType)}
            </Typography>
          )}
        </Box>
        {isSelf && <EditCompanyDescription displayId={displayId} handleClose={handleCloseModal} isOpen={open} />}

        {!hideAllButtons && (
          <Box sx={styles.actionsWrap}>
            <CompanyActionsButtons isRequestButtons={isProductsList} onRequestQuoteClick={onRequestQuoteClick} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
