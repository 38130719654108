import { Dispatch, FC, SetStateAction } from 'react';

import { Control } from 'react-hook-form';
import { Box } from '@mui/material';

import { Quote, UnitsType } from 'app/api/quotes';
import { CommonButton } from 'components';
import { CreateOrderWrapper } from 'pages/DashboardPage/components';
import { QuoteTableFormData } from 'pages/DashboardPage/types';
import { useModal } from 'hooks';
import { QuoteTotal } from '../QuoteTotal';
import { styles } from './styles';
import { ReviseModal } from '../ReviseModal';

interface QuoteRequestActionsProps {
  control: Control<QuoteTableFormData>;
  isAllConfirmedOrNotAvailable: boolean;
  isAllProductsNotAvailable: boolean;
  isAllReadyToBeConfirmedOrNotAvailable: boolean;
  buyerCanAccept: boolean;
  isBuyer: boolean;
  isCanceledQuote: boolean;
  quoteData: Quote;
  totalAmounts: { unit: UnitsType; amount: number }[];
  totalPrice: string | number;
  unitsType: UnitsType;
  isSellerInputNeeded?: boolean;
  addProducts: () => void;
  isEditMode: boolean;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
}

export const QuoteRequestActions: FC<QuoteRequestActionsProps> = ({
  control,
  isAllConfirmedOrNotAvailable,
  isAllProductsNotAvailable,
  isAllReadyToBeConfirmedOrNotAvailable,
  buyerCanAccept,
  isBuyer,
  isEditMode,
  setIsEditMode,
  isCanceledQuote,
  quoteData,
  totalAmounts,
  totalPrice,
  unitsType,
  isSellerInputNeeded = false,
  addProducts,
}) => {
  const { open: isReviseOpen, handleOpenModal: handleOpenRevise, handleCloseModal: handleCloseRevise } = useModal();
  return (
    <Box sx={styles.wrap}>
      <QuoteTotal isSellerInputNeeded={isSellerInputNeeded} totalAmounts={totalAmounts} totalPrice={totalPrice} />
      {isBuyer && (
        <Box sx={styles.sendButtons}>
          {(buyerCanAccept && !isEditMode) || isAllConfirmedOrNotAvailable ? (
            <CreateOrderWrapper
              data={quoteData?.quoteproducts || []}
              sx={styles.buyerSendButton}
              unitsType={unitsType}
              control={control}
              quoteId={quoteData.id}
              isDisabled={isCanceledQuote || isAllProductsNotAvailable}
              seller={quoteData?.seller}
              currentCycle={quoteData?.dealInteractionsCounter ?? 0}
              isSampleRequest={!!quoteData?.isSampleType}
            />
          ) : (
            <CommonButton
              color="primary"
              type="submit"
              sx={styles.buyerSendButton}
              disabled={isCanceledQuote || !isAllReadyToBeConfirmedOrNotAvailable}
            >
              Send
            </CommonButton>
          )}
        </Box>
      )}
      <Box sx={styles.buttons(isBuyer)}>
        {isBuyer && (
          <CommonButton
            disabled={isCanceledQuote}
            color="secondary"
            sx={{ ...styles.buttonCommon, ...styles.addButton }}
            onClick={addProducts}
          >
            Add product
          </CommonButton>
        )}
        {isBuyer && (
          <CommonButton
            disabled={isCanceledQuote}
            color="secondary"
            sx={{ ...styles.buttonCommon, ...styles.addButton }}
            onClick={() => setIsEditMode((v) => !v)}
          >
            {isEditMode ? 'Undo' : 'Counter Offer'}
          </CommonButton>
        )}

        {!isBuyer && !isEditMode && (
          <>
            <CommonButton
              color="primary"
              onClick={handleOpenRevise}
              sx={styles.buttonCommon}
              disabled={isCanceledQuote}
            >
              Revise Quote
            </CommonButton>
            <ReviseModal
              isOpen={isReviseOpen}
              handleClose={handleCloseRevise}
              handleConfirm={() => setIsEditMode(true)}
            />
          </>
        )}
        {!isBuyer && isEditMode && (
          <CommonButton
            color="primary"
            type="submit"
            sx={styles.buttonCommon}
            disabled={isCanceledQuote || !isAllReadyToBeConfirmedOrNotAvailable}
          >
            Send
          </CommonButton>
        )}
      </Box>
    </Box>
  );
};
