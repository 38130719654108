import { FC, useCallback } from 'react';

import { generatePath, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { DashboardMainTabs, Quote, QuoteRequestStatus, UnitsType } from 'app/api/quotes';
import { ROUTES } from 'app/routes/constants';
import { LoadingButton } from 'components';
import { useAuth, useModal } from 'hooks';
import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';
import { MobileSelect } from 'components/MobileSelect';
import { useQuoteRequestDetailsForm } from 'pages/DashboardPage/components/QuoteRequestDetailsForm/hooks';
import { SendQuoteRequestPopup } from 'pages/DashboardPage/components/SendQuoteRequestPopup';
import { QuoteDiscussionList } from '../QuoteDiscussionList';
import { styles } from './styles';

interface QuoteDetailsFormProps {
  quoteData: Quote;
}

const declineOptions = [
  { value: 'outOfStock', label: 'Out of Stock' },
  { value: 'insufficientStock', label: 'Insufficient Stock' },
  { value: 'geographicReasons', label: 'Geographic Reasons' },
  { value: 'other', label: 'Other' },
];

export const QuoteDetailsForm: FC<QuoteDetailsFormProps> = ({ quoteData }) => {
  const navigate = useNavigate();
  const { isBuyer } = useAuth();
  const {
    open: isSendQuoteModalOpen,
    handleOpenModal: handleOpenSendQuoteModal,
    handleCloseModal: handleCloseSendQuoteModal,
  } = useModal();
  const {
    open: isCancelQuoteModalOpen,
    handleOpenModal: handleOpenCancelQuoteModal,
    handleCloseModal: handleCloseCancelQuoteModal,
  } = useModal();

  const { handleTrackLinkClickEvent } = useLinkClickedEvent();

  const isSampleRequest = !!quoteData?.isSampleType;

  const handleNavigateToDashboard = useCallback(() => {
    const tab = isSampleRequest ? DashboardMainTabs.SAMPLE_REQUESTS : DashboardMainTabs.QUOTE_REQUESTS;
    const path = generatePath(isBuyer ? ROUTES.buyer.dashboard.tab : ROUTES.seller.dashboard.tab, { tab });
    navigate(path);
  }, [isBuyer, isSampleRequest, navigate]);

  const {
    watch,
    control,
    setValue,
    trigger,
    clearErrors,
    onSubmit,
    increaseConfirmedProducsCount,
    decreaseConfirmedProducsCount,
    handleSubmit,
    handleCancel,
    isLoadingCancel,
    isWaiting,
    isAllProductsNotAvailable,
    isAllConfirmedOrNotAvailable,
    isAllReadyToBeConfirmedOrNotAvailable,
    buyerCanAccept,
  } = useQuoteRequestDetailsForm({
    quoteData,
    data: quoteData?.quoteproducts || [],
    quoteId: quoteData?.id,
    waitAnswer: quoteData?.waitAnswer,
    isSampleRequest,
    handleOpenSendQuoteModal,
    handleClose: handleNavigateToDashboard,
  });

  const declineDescription =
    `Are you sure you want to decline this quote request from ` +
    `${quoteData.buyer.name} If so, please share a reason.`;

  const addProducts = useCallback(() => {
    const path = generatePath(isSampleRequest ? ROUTES.buyer.sampleRequestAdd : ROUTES.buyer.quoteRequestAdd, {
      companyDisplayId: quoteData?.seller.displayId,
      quoteId: quoteData.id,
    });

    navigate(path);
    handleTrackLinkClickEvent({ text: 'Add Product', path, type: AnalyticsLinkType.BUTTON });
  }, [isSampleRequest, quoteData?.seller.displayId, quoteData.id, navigate, handleTrackLinkClickEvent]);

  const isCanceledQuote = quoteData?.status === QuoteRequestStatus.CANCELED;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <QuoteDiscussionList
        control={control}
        isWaiting={isWaiting}
        isAllProductsNotAvailable={isAllProductsNotAvailable}
        isAllConfirmedOrNotAvailable={isAllConfirmedOrNotAvailable}
        isCanceledQuote={isCanceledQuote}
        isBuyer={isBuyer}
        quoteData={quoteData}
        quoteId={quoteData.id}
        unitsType={quoteData?.unitsType ?? UnitsType.KG}
        addProducts={addProducts}
        increaseConfirmedProducsCount={increaseConfirmedProducsCount}
        decreaseConfirmedProducsCount={decreaseConfirmedProducsCount}
        closeDetails={handleCloseSendQuoteModal}
        setValue={setValue}
        trigger={trigger}
        clearErrors={clearErrors}
        watch={watch}
        handleNavigateToDashboard={handleNavigateToDashboard}
        buyerCanAccept={buyerCanAccept}
        isAllReadyToBeConfirmedOrNotAvailable={isAllReadyToBeConfirmedOrNotAvailable}
      />

      {!isCanceledQuote && (
        <Box sx={styles.actionsWrap}>
          <LoadingButton
            loading={isLoadingCancel}
            variant="text"
            color="inherit"
            sx={styles.cancelButton}
            onClick={() => (isBuyer ? handleCancel() : handleOpenCancelQuoteModal())}
            disabled={isCanceledQuote}
          >
            Cancel request
          </LoadingButton>
          <MobileSelect
            isOpen={isCancelQuoteModalOpen}
            handleClose={handleCloseCancelQuoteModal}
            title="Decline Quote?"
            description={declineDescription}
            options={declineOptions}
            onChange={handleCancel}
          />
        </Box>
      )}

      {isSendQuoteModalOpen && (
        <SendQuoteRequestPopup
          title="Send Quote Request?"
          handleClose={handleCloseSendQuoteModal}
          closeDetails={handleNavigateToDashboard}
          isOpen={isSendQuoteModalOpen}
          data={quoteData?.quoteproducts || []}
          unitsType={quoteData?.unitsType ?? UnitsType.KG}
          quoteId={quoteData.id}
          control={control}
          seller={quoteData?.seller}
          currentCycle={quoteData?.dealInteractionsCounter ?? 0}
          isSampleRequest={!!quoteData?.isSampleType}
        />
      )}
    </form>
  );
};
