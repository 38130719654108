import { Navigate, Route } from 'react-router-dom';
import { lazy } from 'react';

import { AuthProvider } from 'app/providers';
import {
  Notification,
  ProtectedBuyerRoute,
  ProtectedRoute,
  ProtectedSellerRoute,
  PublicRoute,
  ProtectedMobileRoute,
} from 'components';
import { Pagelayout } from 'layout/PageLayout';
import {
  AboutUsPage,
  CreatePasswordPage,
  BuyerHomePage,
  ContactUsPage,
  CompanyDetailsPage,
  ProductDetailsPage,
  DashboardPage,
  PlaceOrderPage,
  ProductsListPage,
  ProductsListPageGuard,
  OrderDetailsPage,
  QuoteRequestPage,
  QuoteDetailsPage,
  FAQPage,
} from 'pages';
import { PagelayoutCorrected } from 'layout/PageLayoutCorrected';
import { PublicSiteModal } from 'components/PublicSiteModal';
import { PopupOnboarding } from 'components/PopupOnboarding/PopupOnboarding';

import { ROUTES } from './constants';

// eslint-disable-next-line import/no-cycle
const CreateNewPasswordPage = lazy(() => import('pages/CreateNewPasswordPage'));
const ChangePasswordPage = lazy(() => import('pages/ChangePasswordPage'));
const ResetPasswordPage = lazy(() => import('pages/ResetPasswordPage'));
const QuoteCreatePage = lazy(() => import('pages/QuoteCreatePage'));

const EditProductsPage = lazy(() => import('pages/EditProductsPage'));
const ShipmentTrackingPage = lazy(() => import('pages/ShipmentTrackingPage'));
const NotificationsPage = lazy(() => import('pages/NotificationsPage'));
const OnboardingPage = lazy(() => import('pages/OnboardingPage'));
const BuyerOnboardingQuestionairePage = lazy(() => import('pages/BuyerOnboardingQuestionairePage'));
const SellerOnboardingRestrictionPage = lazy(() => import('pages/SellerOnboardingRestrictionPage'));
const MaintenancePage = lazy(() => import('pages/MaintenancePage'));
const LegalPrivacyPolicyPage = lazy(() => import('pages/LegalPrivacyPolicyPage'));
const LegalTermsOfServicePage = lazy(() => import('pages/LegalTermsOfServicePage'));
const SavedProductsPage = lazy(() => import('pages/SavedProductsPage'));
const QuoteFormPage = lazy(() => import('pages/QuoteFormPage'));

export const routes = (
  <Route
    element={
      <AuthProvider>
        <Notification />
        <PublicSiteModal />
        <PopupOnboarding />
      </AuthProvider>
    }
  >
    <Route path={ROUTES.common.maintenance} element={<MaintenancePage />} />

    <Route element={<PublicRoute />}>
      <Route path={ROUTES.auth.resetPassword} element={<ResetPasswordPage />} />
      <Route path={ROUTES.auth.accountActivation} element={<CreatePasswordPage />} />
      <Route path={ROUTES.auth.createNewPassword} element={<CreateNewPasswordPage />} />
      <Route element={<Pagelayout />}>
        <Route path={ROUTES.common.privacyPolicy} element={<LegalPrivacyPolicyPage />} />
        <Route path={ROUTES.common.termsOfService} element={<LegalTermsOfServicePage />} />
        <Route path={ROUTES.common.faq} element={<FAQPage />} />
        <Route path={ROUTES.common.about} element={<AboutUsPage />} />
        <Route path={ROUTES.common.contact} element={<ContactUsPage />} />
        <Route path={ROUTES.buyer._} element={<BuyerHomePage />} />
        <Route path={`${ROUTES.buyer.products}`} element={<ProductsListPageGuard />}>
          <Route path="" element={<ProductsListPage />} />
        </Route>
        <Route path={`${ROUTES.buyer.productsExtended}`} element={<ProductsListPageGuard />}>
          <Route path="" element={<ProductsListPage />} />
        </Route>
        <Route path={ROUTES.common.product} element={<ProductDetailsPage />} />
        <Route path={ROUTES.common.quoteForm} element={<QuoteFormPage />} />
      </Route>
    </Route>

    <Route element={<ProtectedRoute />}>
      <Route path={ROUTES.common.changePassword} element={<ChangePasswordPage />} />

      <Route element={<Pagelayout />}>
        <Route path={ROUTES.common.company} element={<CompanyDetailsPage />} />
        <Route path={ROUTES.common.order} element={<OrderDetailsPage />} />
        <Route path={ROUTES.common.shipmentTracking} element={<ShipmentTrackingPage />} />
        <Route path={ROUTES.common.onboarding._} element={<OnboardingPage />} />
        <Route path={ROUTES.common.onboarding.questionaire} element={<BuyerOnboardingQuestionairePage />} />

        <Route element={<ProtectedSellerRoute />}>
          <Route path={`${ROUTES.seller.dashboard._}/*`} element={<DashboardPage />} />
          <Route path={ROUTES.seller.create.quote} element={<QuoteCreatePage />} />
          <Route path={ROUTES.common.onboarding.geoRestrictions} element={<SellerOnboardingRestrictionPage />} />
        </Route>

        <Route element={<ProtectedBuyerRoute />}>
          <Route path={`${ROUTES.buyer.dashboard._}/*`} element={<DashboardPage />} />
          <Route path={ROUTES.buyer.placeOrder} element={<PlaceOrderPage />} />
          <Route path={ROUTES.buyer.quoteRequest} element={<QuoteRequestPage />} />
          <Route path={ROUTES.buyer.sampleRequest} element={<QuoteRequestPage />} />
          <Route path={ROUTES.buyer.quoteRequestCompany} element={<QuoteRequestPage />} />
          <Route path={ROUTES.buyer.sampleRequestCompany} element={<QuoteRequestPage />} />
          <Route path={ROUTES.buyer.quoteRequestAdd} element={<QuoteRequestPage />} />
          <Route path={ROUTES.buyer.sampleRequestAdd} element={<QuoteRequestPage />} />
          <Route path={ROUTES.buyer.savedProducts} element={<SavedProductsPage />} />
        </Route>

        <Route element={<ProtectedMobileRoute />}>
          <Route path={ROUTES.common.notifications} element={<NotificationsPage />} />
          <Route path={ROUTES.common.quoteRequestDetails} element={<QuoteDetailsPage />} />
          <Route path={ROUTES.common.sampleRequestDetails} element={<QuoteDetailsPage />} />
        </Route>
      </Route>
      <Route element={<PagelayoutCorrected />}>
        <Route element={<ProtectedSellerRoute />}>
          <Route path={ROUTES.seller.edit._} element={<EditProductsPage />} />
        </Route>
      </Route>
    </Route>
    <Route path="*" element={<Navigate to={ROUTES.buyer._} replace />} />
  </Route>
);
