import { FC } from 'react';

import { Box } from '@mui/material';

import { useSearchParams } from 'react-router-dom';
import { PageLoader } from 'components';
import { SCROLLABLE_LIST_ID } from 'components/ProductsList/components/InfinityListWrapper';
import { Footer } from 'layout/Footer';
import OnboardingBanner from 'components/OnboardingBanner';

import { Description, PageHeader, TeamList, CompanyProducts, MobileCompanyActionsButtons } from './components';
import { useCompanyDetailsPageHanlers } from './hooks';
import { styles } from './styles';

export const CompanyDetailsPage: FC = () => {
  const {
    isSelf,
    isBuyer,
    isLoading,
    data,
    products,
    isTeamList,
    isProductsList,
    isProductsNextPageLoading,
    hasProductsNextPage,
    hideAllButtons,
    onLoadMore,
    onRequestQuoteClick,
  } = useCompanyDetailsPageHanlers();
  const [searchParams] = useSearchParams();
  const displayOnboardingBanner = searchParams.get('onboarding');

  if (isLoading) {
    return (
      <Box sx={{ ...styles.loaderWrap }}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <>
      <Box id={SCROLLABLE_LIST_ID} sx={styles.pageWrap(isBuyer)}>
        {data ? (
          <>
            {displayOnboardingBanner && <OnboardingBanner containerStyles={styles.bannerStyles(isBuyer)} />}
            <PageHeader
              displayId={data.displayId}
              logo={data.logo}
              name={data.name}
              address={data.address}
              categories={data.meta?.categories}
              isSelf={isSelf}
            />
            <Description
              isSelf={isSelf}
              displayId={data.displayId}
              description={data.description}
              image={data.image}
              minOrder={data.smallestShipment}
              minOrderType={data.smallestShipmentType}
              hideAllButtons={hideAllButtons}
              isProductsList={!!products.length}
              onRequestQuoteClick={onRequestQuoteClick}
            />
            {isTeamList && <TeamList data={data.teamMembers} isSelf={isSelf} />}
            {isProductsList && (
              <CompanyProducts
                data={products}
                hasNextPage={hasProductsNextPage}
                isLoading={isProductsNextPageLoading}
                onLoadMore={onLoadMore}
              />
            )}
            <Box sx={styles.footerWrap(!hideAllButtons)}>
              <Footer />
            </Box>
            {!hideAllButtons && (
              <MobileCompanyActionsButtons
                isRequestButtons={isProductsList}
                onRequestQuoteClick={onRequestQuoteClick}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};
