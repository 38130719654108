import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  productContainer: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    flexDirection: 'column',
    gap: '8px',
    padding: '8px',
  },

  topRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flexDirection: 'column',
    },
  },

  productNameContainer: {
    flex: 1,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'flex',
      width: '100%',
    },
  },

  productInputContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
    },
  },
  numberWraper: {
    margin: '0px',
    width: '100%',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
    },
  },
  quantityAvailableInputWrap: {},
  quantityAvailableInput: {},
  unitsMenu: {},

  productItem: {
    minHeight: '42px',
    margin: '0px',
    flex: 1,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
    },
  },
  selectContainer: {
    display: 'flex',
    width: '100%',
    gap: '8px',
  },
  rowItem: {
    flex: 1,
  },
  selectInput: {
    padding: '0px',
    fontSize: '12px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minHeight: '42px',
    },
  },
};
