import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { FooterLinks } from '../FooterLinks';
import { styles } from './styles';

export const Footer: FC = () => {
  return (
    <Box sx={{ ...styles.mainWrap }}>
      <Box sx={{ ...styles.wrap }}>
        <FooterLinks />
        <Typography sx={{ ...styles.companyInfo }}>Sproutzo Inc.</Typography>
      </Box>
    </Box>
  );
};
