import { ROUTES } from 'app/routes/constants';

export const ROUTES_TO_HIDE_MOBILE_FOOTER = [
  ROUTES.common.company,
  ROUTES.common.product,
  ROUTES.buyer.products,
  ROUTES.buyer.productsExtended,
  ROUTES.common.quoteRequestDetails,
  ROUTES.common.sampleRequestDetails,
];
