import { FC } from 'react';

import { format } from 'date-fns';
import { Box, Typography } from '@mui/material';

import { OrderStatus } from 'app/api/orders';
import { quoteStatusLabel } from 'components/QuoteStatusTag/constants';
import { ViewShipmentButton } from '../ViewShipmentButton';
import { styles } from './styles';

interface PageHeaderProps {
  isViewShipmentTrackingButton: boolean;
  status: OrderStatus;
  orderNumber?: string;
  orderShipmentId: number | string;
  createdAt?: string | null;
  estimatedDeliveryDate?: string | null;
  deliveredAt?: string | null;
}

const dateFormat = 'dd.MM.yyyy';

export const PageHeader: FC<PageHeaderProps> = ({
  isViewShipmentTrackingButton,
  status,
  orderNumber = '',
  orderShipmentId = '',
  createdAt = '',
  estimatedDeliveryDate = '',
  deliveredAt = '',
}) => {
  const createdDate = createdAt ? format(new Date(createdAt), dateFormat) : '';
  const deliveredAtEstimated = estimatedDeliveryDate ? format(new Date(estimatedDeliveryDate), dateFormat) : 'Pending';
  const deliveredAtFact = deliveredAt ? format(new Date(deliveredAt), dateFormat) : null;

  return (
    <Box sx={{ ...styles.wrap }}>
      <Box sx={{ ...styles.metaWrap }}>
        <Box sx={styles.titleWrap}>
          <Typography component="h2" sx={{ ...styles.title }}>
            Order #{orderNumber}
          </Typography>
          {isViewShipmentTrackingButton && (
            <Box sx={styles.trackingButtonWrap}>
              <ViewShipmentButton orderShipmentId={orderShipmentId} />
            </Box>
          )}
        </Box>
        <Typography sx={{ ...styles.date }}>Date Created: {createdDate}</Typography>
        {deliveredAt ? (
          <Typography sx={{ ...styles.date }}>Date Delivered: {deliveredAtFact}</Typography>
        ) : (
          <Typography sx={{ ...styles.date }}>Estimated Delivery Date: {deliveredAtEstimated}</Typography>
        )}
      </Box>
      <Box sx={styles.rightWrap}>
        <Typography sx={{ ...styles.tag }}>{quoteStatusLabel[status]}</Typography>
      </Box>
    </Box>
  );
};
