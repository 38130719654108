import { AutocompleteInput } from 'pages/EditProductsPage/components/AutocompleteInput';
import { useCountries } from 'hooks/api/useCountriesQuery';
import { FC, useEffect, useState } from 'react';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { Control, RegisterOptions, useController } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { ReactComponent as ErrorIcon } from '../../assets/icons/errorSmall.svg';

import { styles } from './styles';

export interface CountryAutocompleteProps {
  onChange: (val: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, object>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
}

export const CountryAutocomplete: FC<CountryAutocompleteProps> = ({ control, rules, onChange }) => {
  const { data: countries = [] } = useCountries();
  const choices = countries.map((country) => ({ id: country.id, name: country.name }));
  const [value, setValue] = useState<number | undefined>(undefined);
  useEffect(() => {
    const choice = choices.find((choice) => choice.id === value);
    if (choice) {
      onChange(choice?.name ?? '');
    }
  }, [onChange, choices, value]);

  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'country',
    control,
    rules,
  });
  return (
    <Box sx={styles.container}>
      <AutocompleteInput
        label=""
        field=""
        initialValue={field.value}
        value={value}
        description=""
        full
        containerSx={styles.autocompleteContainer(!!error?.message && !value) as SxPropsTypes}
        inputSx={styles.input as SxPropsTypes}
        optionSx={styles.option as SxPropsTypes}
        noResultsSx={styles.noResults as SxPropsTypes}
        choiceLimit={8}
        choices={choices}
        freeSolo
        placeholder="Country"
        onChange={(field, val) => {
          if (typeof val === 'string') {
            setValue(undefined);
            onChange(val);
          } else if (typeof val === 'number') {
            setValue(val);
          }
        }}
      />
      {error?.message && !value && (
        <Box sx={{ ...styles.errorBox }}>
          <Box sx={{ ...styles.errorIcon }}>
            <ErrorIcon />
          </Box>
          <Typography sx={{ ...styles.errorText }}>{error?.message}</Typography>
        </Box>
      )}
    </Box>
  );
};
