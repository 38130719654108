import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import 'intl-tel-input/build/css/intlTelInput.css';
import TagManager from 'react-gtm-module';
import { PostHogProvider } from 'posthog-js/react';

import { initializeSentry } from './initializeSentry';
import { routes } from './routes/routes';
import { AppWrapper } from './AppWrapper';
import { store } from '../state/store';
import { initPostHog } from './providers/PostHogWrapProvider';

const createdRoutes = createRoutesFromElements(routes);
const router = createBrowserRouter(createdRoutes);

initializeSentry();
const posthogClient = initPostHog();

const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';
const GTM_KEY = process.env.REACT_APP_GTM_KEY;
if (GTM_KEY) {
  const tagManagerArgs = {
    gtmId: GTM_KEY,
  };
  TagManager.initialize(tagManagerArgs);
}

// Comment out to test country autocomplete
// if (!IS_PRODUCTION) {
//   window.location.replace('https://sproutzo.com');
// }

export const App = () => {
  const app = (
    <Provider store={store}>
      {!IS_PRODUCTION && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}

      <AppWrapper>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
      </AppWrapper>
    </Provider>
  );
  if (posthogClient) {
    return <PostHogProvider client={posthogClient}>{app}</PostHogProvider>;
  }
  return app;
};
