import { FC } from 'react';

import { Box } from '@mui/material';

import { CommonButton } from 'components';
import { styles } from './styles';

interface CompanyActionsButtonsProps {
  isRequestButtons: boolean;
  onRequestQuoteClick?: () => void;
}

export const CompanyActionsButtons: FC<CompanyActionsButtonsProps> = ({ isRequestButtons, onRequestQuoteClick }) => {
  return (
    <Box sx={styles.actionsWrap}>
      {isRequestButtons && (
        <>
          <CommonButton sx={{ ...styles.btn, ...styles.quoteButton }} onClick={onRequestQuoteClick}>
            Request Quote
          </CommonButton>
        </>
      )}
    </Box>
  );
};
