import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  inputContainer: {
    position: 'relative',
    '.base-Input-input': {
      fontWeight: '500',
      fontSize: '14px',
      height: '32px',
      borderRadius: '8px',
      border: '1px solid var(--Black-10, #E8E8E8)',
      background: 'white',
      padding: '4px 6px',
      textAlign: 'left',
      width: '100%',
      boxSizing: 'border-box',
      backgroundColor: customTheme.custom.black.B5,
      fontFamily: customTheme.typography.allVariants,
    },
  },
  popper: {
    zIndex: 100,
    width: '100%',
    animation: `${customTheme.animations.fadeIn} 0.1s ease-in-out`,
  },
  list: {
    animation: `${customTheme.animations.height} 0.1s ease-in-out`,
    listStyleType: 'none',
    margin: 0,
    marginTop: '4px',
    padding: '4px',
    boxSizing: 'border-box',
    backgroundColor: customTheme.custom.white,
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)',
  },
  listItem: {
    padding: '4px',
    borderRadius: '4px',
    transition: 'all 0.3s ease-in-out',
    fontWeight: 500,
    fontSize: '14px',
    color: customTheme.custom.black.B70,

    ':hover': {
      backgroundColor: customTheme.custom.black.B5,
      cursor: 'pointer',
    },

    '&[aria-selected=true]': {
      backgroundColor: customTheme.custom.black.B5,
    },
    '&.Mui-focused, &.Mui-focusVisible': {
      backgroundColor: customTheme.custom.black.B5,
    },
  },
  noResults: {
    fontWeight: 500,
    fontSize: '14px',
    color: customTheme.custom.black.B70,
  },
};
