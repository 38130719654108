import { FC } from 'react';

import { Box } from '@mui/material';

import { Quote } from 'app/api/quotes';
import { PageHeader } from '../PageHeader';
import { QuoteDetailsForm } from '../QuoteDetailsForm';
import { usePageInner } from './hooks';
import { styles } from './styles';

interface PageInnerProps {
  data: Quote;
}

export const PageInner: FC<PageInnerProps> = ({ data }) => {
  const { isBuyer, isSampleRequest, isWaiting, subtitle, title } = usePageInner({ data });

  return (
    <Box sx={styles.wrap(isBuyer)}>
      <PageHeader
        isSampleRequest={isSampleRequest}
        isBuyer={isBuyer}
        isWaiting={isWaiting}
        subtitle={subtitle}
        title={title}
      />
      <QuoteDetailsForm quoteData={data} />
    </Box>
  );
};
