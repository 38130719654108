import { FC, Fragment } from 'react';

import { Box, Typography } from '@mui/material';

import { OrderStatus } from 'app/api/orders';
import { PageLoader, DocumentsPreview } from 'components';
import { useDeviceType } from 'hooks';
import { PageHeader, OrderInfo, ShipmentInfoCard, InfoMessage, PaymentInfo, OrderDocumentsList } from './components';
import { useOrderDetailsPageHanlers } from './hooks';
import { styles } from './styles';

const messages = {
  payment: 'Sproutzo team is working on payment with the buyer. We&apos;ll be in touch with you shortly.',
  freightQuote: 'This shipment is being organized. We will update you shortly!',
};

export const OrderDetailsPage: FC = () => {
  const { isMobileSize } = useDeviceType();
  const {
    isBuyer,
    isLoading,
    orderData,
    showPaymentInfo,
    paymentInfoData,
    isBuyerActionsWithDocumentsAllowed,
    isSellerActionsWithDocumentsAllowed,
    isViewShipmentTrackingButton,
    deliveryAddress,
    documentsData,
    isDocumentsFetching,
    currentDocument,
    isDocumentOpen,
    handleOpenDocumentView,
    handleCloseDocumentView,
  } = useOrderDetailsPageHanlers();

  const MainInfoWrap = isMobileSize ? Fragment : Box;

  if (isLoading) {
    return (
      <Box sx={styles.loaderWrap}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <>
      <Box sx={styles.pageWrap}>
        {orderData ? (
          <>
            <MainInfoWrap sx={styles.mainInfo}>
              <PageHeader
                orderShipmentId={orderData?.orderShipmentId ?? ''}
                isViewShipmentTrackingButton={isViewShipmentTrackingButton}
                orderNumber={orderData.number}
                createdAt={orderData.createdAt}
                estimatedDeliveryDate={orderData.estimatedDelivery}
                deliveredAt={orderData.deliveredAt}
                status={orderData.status}
              />
              {!isBuyer && orderData.status === OrderStatus.PAYMENT_IN_PROGRESS && (
                <InfoMessage>{messages.payment}</InfoMessage>
              )}
              {orderData.status === OrderStatus.AWAITING_FREIGHT_QUOTE ? (
                <InfoMessage>{messages.freightQuote}</InfoMessage>
              ) : (
                <ShipmentInfoCard
                  isViewShipmentTrackingButton={isViewShipmentTrackingButton}
                  shipmentType={orderData.shipmentType}
                  freightPrice={orderData.freightPrice}
                  palletType={orderData.palletType}
                  length={orderData.length}
                  width={orderData.width}
                  height={orderData.height}
                  weight={orderData.weight}
                  numberOfUnits={orderData.numberOfUnits}
                  containerType={orderData.containerType}
                  orderShipmentId={orderData?.orderShipmentId ?? ''}
                />
              )}
              {showPaymentInfo && !!paymentInfoData && <PaymentInfo {...paymentInfoData} />}

              <Box sx={{ ...styles.titleWrap }}>
                <Typography sx={{ ...styles.title }}>Required Documents</Typography>
                {!isBuyer && <Typography sx={{ ...styles.subTitle }}>Acceptable Formats: PDF, PNG, JPEG</Typography>}
              </Box>

              {isDocumentsFetching ? (
                <Box sx={{ ...styles.loaderBox }}>
                  <PageLoader />
                </Box>
              ) : (
                <OrderDocumentsList
                  isBuyerActionsAllowed={isBuyerActionsWithDocumentsAllowed}
                  isSellerActionsAllowed={isSellerActionsWithDocumentsAllowed}
                  orderId={orderData.id}
                  quoteId={orderData.quote.id}
                  isSampleRequest={orderData.quote.isSampleType}
                  documents={documentsData}
                  handleOpenDocumentView={handleOpenDocumentView}
                />
              )}
            </MainInfoWrap>
            <OrderInfo
              isBuyer={isBuyer}
              isMobileSize={isMobileSize}
              {...orderData}
              deliveryAddress={deliveryAddress}
              shippingWeight={orderData.weight}
            />
          </>
        ) : (
          <></>
        )}
      </Box>

      {!!currentDocument && (
        <DocumentsPreview file={currentDocument} isOpen={isDocumentOpen} handleClose={handleCloseDocumentView} />
      )}
    </>
  );
};
