import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { DropzoneRootProps, useDropzone } from 'react-dropzone';
import { Box, CircularProgress, SxProps, Typography } from '@mui/material';

import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { ImageMutationResult, MutationResult } from 'pages/EditProductsPage/hooks/useProducts';
import { ProductDetail, ProductEdit, ProductOtherAttribute } from 'app/api/products';
import { useEditProduct } from 'pages/EditProductsPage/hooks/useProduct';
import { CommonButton, CommonModal } from 'components';
import { OtherFieldMapping, Validator, convertProductDetailsToFlatForm, otherFieldMapping } from 'utils/productSurvey';

import { useCropFamiliesQuery } from 'hooks';
import { notificationObserver } from 'utils/observer';
import { Crop } from 'app/api/crops';

import { styles } from './styles';
import { sections } from './sections';
import { SelectInput, SelectInputProps } from '../SelectInput';
import { Input, InputProps, LongFormInput } from '../TextInput';
import { MinMaxNumberInput, MinMaxNumberInputProps } from '../MinMaxNumberInput';
import { GeographyRestrictions } from '../GeographyRestrictions';
import { Section } from '../Section';
import { AutocompleteInput, AutocompleteInputProps } from '../AutocompleteInput';
import { ConfirmModal } from '../ConfirmModal';
import { ColorInput } from '../ColorInput';

type ItemType =
  | ({
      type: 'autocomplete';
    } & AutocompleteInputProps)
  | ({
      type: 'choice';
    } & SelectInputProps)
  | ({
      type: 'text';
    } & InputProps)
  | ({
      type: 'long';
      rows?: number;
    } & InputProps)
  | ({
      type: 'min-max-number';
    } & MinMaxNumberInputProps)
  | {
      type: 'header';
      text: string;
    };

export const getFieldType = (mapping: OtherFieldMapping): 'choice' | 'text' | 'long' | 'min-max-number' => {
  if (mapping.validator === Validator.STRING && 'choices' in mapping) {
    return 'choice';
  }
  if (mapping.validator === Validator.NUMBER_MIN_MAX_UNITS) {
    return 'min-max-number';
  }
  if (mapping.validator === Validator.STRING && 'rows' in mapping) {
    return 'long';
  }
  return 'text';
};

export const generateItems = (
  crop: string,
  productEdit: ProductEdit,
  fields: (keyof ProductEdit)[],
  onChange: (key: string, value: ProductOtherAttribute | null | undefined) => void,
): ItemType[] => {
  return fields
    .filter((field) => {
      const mapping = otherFieldMapping[field];
      if (mapping.restrictedCrops) return mapping.restrictedCrops.has(crop);
      return true;
    })
    .map((field) => {
      const value = productEdit[field];
      const mapping = otherFieldMapping[field];
      const type = getFieldType(mapping);
      return {
        type,
        label: otherFieldMapping[field].label,
        key: otherFieldMapping[field].key,
        field,
        placeholder: otherFieldMapping[field].placeholder,
        description: otherFieldMapping[field].description,
        value,
        onChange,
        full: type === 'long',
        ...{ text: 'text' in mapping ? mapping.text : undefined },
        ...{ rows: 'rows' in mapping ? mapping.rows : undefined },
        ...{ choices: 'choices' in mapping ? mapping.choices : undefined },
        ...{ units: 'units' in mapping ? mapping.units : undefined },
      } as ItemType;
    });
};

const GeneralSection: FC<{
  cropFamiliesData: {
    crops: Crop[];
    id: number;
    name: string;
  }[];
  onChange: (field: string, value: string | number) => void;
  productEdit: ProductEdit;
}> = ({ cropFamiliesData, onChange, productEdit }) => {
  const crops = useMemo(() => cropFamiliesData?.flatMap((cropFamily) => cropFamily.crops) ?? [], [cropFamiliesData]);
  const cropId = productEdit?.crop_id || crops.find((crop) => crop.name === productEdit?.crop)?.id;
  const onChangeCrop = useCallback(
    (key: string, cropId: number) => {
      onChange(key, cropId);
      const cropName = crops.find((crop) => crop.id === cropId)?.name || '';
      onChange('crop', cropName);
    },
    [onChange, crops],
  );
  const items: ItemType[] = [
    { type: 'text', label: 'Name', field: 'name', value: productEdit?.name || '', onChange, full: true } as ItemType,
    {
      type: 'text',
      label: 'Type',
      field: 'variety',
      value: productEdit?.variety || '',
      onChange,
      full: true,
    } as ItemType,
    {
      type: 'autocomplete',
      label: 'Crop',
      field: 'crop_id',
      value: cropId,
      choices: crops,
      onChange: onChangeCrop,
      full: true,
    } as ItemType,
    {
      type: 'long',
      label: 'Description',
      field: 'description',
      value: productEdit?.description || '',
      onChange,
    } as ItemType,
  ];

  return (
    <AttributesSection
      sx={styles.generalSection}
      isCollapsible={false}
      items={items}
      onChange={onChange}
      productEdit={productEdit}
    />
  );
};

const AttributesSection: FC<{
  title?: string;
  isCollapsible?: boolean;
  items: ItemType[];
  onChange: (field: string, value: string) => void;
  productEdit: ProductEdit;
  sx?: SxProps;
}> = ({ title, items, sx, isCollapsible = true }) => {
  const [active, setActive] = useState<string | undefined>();
  return (
    <Section sx={sx} title={title} isCollapsible={isCollapsible}>
      {items.map((item) => {
        if (item.type === 'text' && 'label' in item && item?.label === 'Color') {
          return <ColorInput key={item.field} {...item} setActive={setActive} />;
        }
        if (item.type === 'header') {
          return (
            <Typography sx={styles.attributeHeader} key={item.text}>
              {item.text}
            </Typography>
          );
        }
        if (item.type === 'autocomplete') {
          return <AutocompleteInput key={item.field} {...item} />;
        }
        if (item.type === 'text') {
          return <Input key={item.field} {...item} active={active} setActive={setActive} />;
        }
        if (item.type === 'long') {
          return <LongFormInput key={item.field} {...item} />;
        }
        if (item.type === 'choice') {
          return <SelectInput key={item.field} {...item} />;
        }
        if (item.type === 'min-max-number') {
          return <MinMaxNumberInput key={item.field} {...item} />;
        }
        return null;
      })}
    </Section>
  );
};

interface UseFilesDropzoneProps {
  onDragEnter?: () => void;
}

const useFilesDropzone = ({ onDragEnter }: UseFilesDropzoneProps) => {
  const [image, setImage] = useState<{ file: File; url: string } | undefined>();
  const { getRootProps: getDropzoneRootProps } = useDropzone({
    onDrop: (files: File[]) =>
      setImage({
        file: files[0],
        url: URL.createObjectURL(files[0]),
      }),
    onDragOver: onDragEnter,
    maxSize: 15000000,
    multiple: true,
    noClick: false,
  });
  return {
    image,
    getDropzoneRootProps,
  };
};
type ImageSectionProps = {
  image: string | undefined | null;
  dropzoneImage:
    | {
        file: File;
        url: string;
      }
    | undefined;
  getDropzoneRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
};
const ImageSection: FC<ImageSectionProps> = ({ image: src, dropzoneImage, getDropzoneRootProps }) => {
  const displayImage = dropzoneImage?.url ?? src;
  return (
    <Section title="Media" sx={styles.imageSection}>
      <Box {...getDropzoneRootProps()} sx={styles.dropzone}>
        {displayImage ? (
          <Box component="img" sx={styles.imagePreview} src={displayImage} />
        ) : (
          <Box sx={styles.uploadText}>Upload a product image</Box>
        )}
        <Box sx={styles.updateImage}>Update Image</Box>
      </Box>
    </Section>
  );
};

const EditForm: FC<{
  handleClose: () => void;
  handleSave: () => void;
  updateProduct: MutationResult;
  updateProductImage: ImageMutationResult;
  productDetails: ProductDetail;
}> = ({ handleClose, handleSave, productDetails, updateProduct, updateProductImage }) => {
  const { cropFamiliesData } = useCropFamiliesQuery({ enabled: true });
  const [productEdit, setProduct] = useState(convertProductDetailsToFlatForm(productDetails));

  const { image, getDropzoneRootProps } = useFilesDropzone({});
  const saveEditProduct = () => {
    updateProduct?.mutate({
      id: productDetails.id,
      properties: {
        ...productEdit,
        countriesRestrictedAt:
          productEdit.countriesRestrictedAt.length > 0 ? productEdit.countriesRestrictedAt : undefined,
      },
    });
    if (image?.file) {
      updateProductImage?.mutate({ id: productDetails.id, image: image.file });
    }
    notificationObserver.publish({
      type: 'success',
      title: 'Product Updated',
    });
    handleSave();
  };

  const onChange = useCallback(
    (key: string, value: ProductOtherAttribute | null | undefined | number[]) => {
      setProduct((prev) => ({ ...prev, [key]: value }));
    },
    [setProduct],
  );
  const crops = cropFamiliesData?.flatMap((cropFamily) => cropFamily.crops) ?? [];
  const crop = crops.find((crop) => crop.id.toString() === productEdit?.crop_id?.toString());
  const categories = {
    title: 'Attributes',
    items: sections.flatMap((section) => {
      const items = generateItems(crop?.name ?? productDetails.crop, productEdit, section.fields, onChange);
      if (items.length > 0) {
        return [{ type: 'header', text: section.title } as ItemType, ...items];
      }
      return [];
    }),
  };

  return (
    <Box mx={styles.container}>
      <Box mx={styles.containerHeader}>
        <Typography variant="h1" mx={styles.editTitle}>
          Edit Product
        </Typography>
        <Box>
          <CommonButton sx={styles.closeButton} disabled={false} variant="outlined" color="info" onClick={handleClose}>
            Close
          </CommonButton>
          <CommonButton disabled={false} variant="outlined" color="secondary" sx={{}} onClick={saveEditProduct}>
            Save Changes
          </CommonButton>
        </Box>
      </Box>
      <Box sx={styles.topContainer}>
        <ImageSection image={productDetails.image} dropzoneImage={image} getDropzoneRootProps={getDropzoneRootProps} />
        <GeneralSection cropFamiliesData={cropFamiliesData} productEdit={productEdit} onChange={onChange} />
      </Box>
      <Section title="Geography Restrictions" isCollapsible defaultCollpase>
        <GeographyRestrictions countriesRestrictedAt={productEdit.countriesRestrictedAt} onChange={onChange} />
      </Section>
      <AttributesSection
        key={categories.title}
        title={categories.title}
        items={categories.items}
        productEdit={productEdit}
        onChange={onChange}
      />
    </Box>
  );
};

interface EditProductModalProps {
  productId: number;
  updateProduct: MutationResult;
  updateProductImage: ImageMutationResult;
  handleClose: () => void;
  isOpen: boolean;
}

export const EditProductModal: FC<EditProductModalProps> = ({
  productId,
  updateProduct,
  updateProductImage,
  handleClose,
  isOpen,
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const onClose = useCallback(
    (e: MouseEvent, reason?: string) => {
      if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
        setIsConfirmModalOpen(true);
        return;
      }
      handleClose();
    },
    [handleClose],
  );
  const { productDetails, isLoading } = useEditProduct({
    productId,
    isOpen,
  });

  const handleCloseConfirmModal = useCallback(() => setIsConfirmModalOpen(false), []);
  const handleOpenConfirmModal = useCallback(() => setIsConfirmModalOpen(true), []);
  const handleConfirmClose = () => {
    setIsConfirmModalOpen(false);
    handleClose();
  };
  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={onClose}
      withCloseButton={false}
      paperStyles={styles.paperStyles as SxPropsTypes}
    >
      <ConfirmModal isOpen={isConfirmModalOpen} onClose={handleCloseConfirmModal} onConfirm={handleConfirmClose} />
      {isLoading || !productDetails ? (
        <Box sx={styles.loading}>
          <CircularProgress color="primary" size="32px" />
        </Box>
      ) : (
        <EditForm
          handleClose={handleOpenConfirmModal}
          handleSave={handleClose}
          updateProduct={updateProduct}
          updateProductImage={updateProductImage}
          productDetails={productDetails}
        />
      )}
    </CommonModal>
  );
};
