import { FC } from 'react';

import { Box } from '@mui/material';

import { CompanyActionsButtons } from '../CompanyActionsButtons';
import { styles } from './styles';

interface MobileCompanyActionsButtonsProps {
  isRequestButtons: boolean;
  onRequestQuoteClick?: () => void;
}

export const MobileCompanyActionsButtons: FC<MobileCompanyActionsButtonsProps> = ({
  isRequestButtons,
  onRequestQuoteClick,
}) => {
  return (
    <Box sx={styles.wrap}>
      <CompanyActionsButtons isRequestButtons={isRequestButtons} onRequestQuoteClick={onRequestQuoteClick} />
    </Box>
  );
};
