import { OrderStatus } from 'app/api/orders/types';
import { useModal } from 'hooks';
import { useCancelOrderMutation } from '../../hooks';

const ALLOWED_STATUSES_TO_CANCEL = [
  OrderStatus.AWAITING_FREIGHT_QUOTE,
  OrderStatus.DOCUMENTS_IN_PROGRESS,
  OrderStatus.PAYMENT_IN_PROGRESS,
];

interface OrderInfoI {
  status: OrderStatus;
  orderId: number;
}

export const useOrderInfo = ({ status, orderId }: OrderInfoI) => {
  const {
    open: isCancelModalOpen,
    handleOpenModal: handleOpenCancelModal,
    handleCloseModal: handleCloseCancelModal,
  } = useModal();

  const { handleCancelOrder, isCancelOrderLoading } = useCancelOrderMutation({
    id: orderId,
    onSuccess: handleCloseCancelModal,
  });

  const isCancelButton = ALLOWED_STATUSES_TO_CANCEL.includes(status);

  return {
    isCancelModalOpen,
    handleOpenCancelModal,
    handleCloseCancelModal,
    isCancelButton,
    handleCancelOrder,
    isCancelOrderLoading,
  };
};
