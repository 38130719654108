import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  container: {
    flex: 1,
  },
  autocompleteContainer: (hasError: boolean) => ({
    '& input': {
      height: '100px',
      fontSize: '14px',
      '&:focus-visible': {
        border: `1px solid ${customTheme.palette.primary.main} !important`,
        outline: 'none',
      },
      '::placeholder': {
        color: customTheme.custom.black.B50,
        opacity: 1,
      },
      ...(hasError && {
        border: `1px solid ${customTheme.custom.error} !important`,
      }),
    },
  }),
  input: {
    height: '40px',
    borderRadius: '25px',
    padding: '5px 16px 4px',
    border: `1px solid ${customTheme.custom.black.B10}`,
    fontSize: '14px',
    fontWeight: 400,
    color: customTheme.custom.black.B100,
    backgroundColor: customTheme.custom.white,
  },
  option: {},
  noResults: {},
  errorBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1px',
  },
  errorText: {
    fontSize: '12px',
    lineHeight: '16px',
    color: customTheme.custom.error,
  },
  errorIcon: {
    display: 'flex',
    width: '16px',
    height: '16px',
  },
};
